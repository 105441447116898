import React from 'react';

import HeaderV2 from "../../components/HeaderV2/HeaderV2";
import HoverCards from "./SoilHoverCards"
import Footer from "../../components/Footer/Footer";
import ContactUsBtn from "../../components/ContactUsBtn";


import  "../../../styles/ServicesPages.scss";
import SEO from "../../components/SEO";
import {StaticImage} from "gatsby-plugin-image";


function SoilTesting() {

    return (
        <div>
            <SEO title={"Soil Testing - QCTesting"} description={"List of Soil testing we conduct"} url={"www.qctesting.com.au/services/SoilTesting"}
                 image={"../../images/Muddy Site.webp"} keywords={"soil, soil testing, building material testing, laboratory testing, material testing, " +
                "construction testing, construction material testing, top soil, types of soil, soil for plants, compost soil, soil testing near me"}/>

            <div>
                <HeaderV2/>
                <div className={"servicesHead"}>
                    <StaticImage
                        className={"headImage"}
                        src={"../../images/Muddy Site.webp"}
                        alt={"Level 1 job in progress"}
                        placeholder={"blurred"}
                        objectFit={"cover"}
                    />
                    <h1 className={"headerText"}> Soil Testing </h1>
                </div>
                    <div className={"boxShadow"}/>
                    <h1 id={"g"}> Tests We Conduct </h1>
                        <HoverCards/>
                <Footer/>
                <div /* Fix Position Items  */ >
                    <ContactUsBtn className={"contactBtnPosition"} redirect={"Tel:0408623377"}/>
                </div>
            </div>
        </div>
    );
}

export default SoilTesting;
